import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const StudentColumn = styled(Col)`
  margin-top: 50px;
`

const Student = ({student}) => {
  const [showPref, updatePref] = useState(false);

  const renderPreferences = () => {
    return student.availability.map(function (dayObj, i) {
      const dayStr = Object.keys(dayObj)[0];

      const day = dayStr.charAt(0).toUpperCase() + dayStr.slice(1) + 's';

      const renderStudents = () => {
        return dayObj[dayStr].map(function (time, i) {
          if (dayObj[dayStr].length - 1 === i) {
            return <span>{time}</span>
          } else {
            return <span>{time}, </span>
          }

        });
      }

      const renderSpacing = () => {
        if (student.availability.length - 1 !== i) {
          return <br></br>
        }
      }
  
      return (
        <div>
          <strong>{day}:</strong>
          <br></br> 
          <div>{renderStudents()}</div>
          {renderSpacing()}
        </div>
      )
    });
  };

  const renderTogglePreferences = () => {
    if (showPref) {
      return (
        <p className='preferences'>
          I am available these days at these times to collaborate:
          <br></br>
          <br></br>
          {renderPreferences()}
        </p>
      )
    }
  }

  const handleTogglePrefClick = () => {
    updatePref(!showPref);
  }

  const renderShowButton = () => {
    if (showPref) {
      return <span>Hide Collaboration Preferences</span>
    } else {
      return <span>Show Collaboration Preferences</span>
    }
  }

  return (
    <StudentColumn md={4}>
      <div className='test-container'>
        <div className='test-img-box'>
          <img alt='' src={student.image}></img>
        </div>
        <div className='test-info-container'>
          <p className='test-name'>
            {student.firstName} {student.lastName}
          </p>
          <p className='test-name'>
            Program: {student.program}
          </p>
          <p className='test-name'>
            Time Zone: {student.zone}
          </p>
          <p className='test-name'>
            <a href={student.zoom}>Zoom Link</a>
          </p>
        </div>
        <hr/>
        <p className='test-text'>{student.bio}</p>
        <hr/>
        {renderTogglePreferences()}
        <div className='show-more-button' tabIndex={0} role='button' onClick={handleTogglePrefClick} onKeyDown={handleTogglePrefClick}>
          <p>{renderShowButton()}</p>
        </div>
      </div>
    </StudentColumn>
  )
};

export default Student;