import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import styled from 'styled-components'
import { Navbar, Nav } from 'react-bootstrap';  

import logoWhite from '../../assets/images/parsity-logo-white.png'
import logoBlack from '../../assets/images/parsity-logo-black.png'

const StyledNavbar = styled(Navbar)`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px;

  .navbar-collapse.show {
    background-color: var(--white);
  }

  .logo img {
    height: 60px;
    margin-bottom: 0;
    padding-left: 20px;
  }

  .NavItem {
    margin-right: 15px;
    margin-left: 15px;
    color: var(--white);

    a {
      color: var(--white);
    }
  }

  .black-logo {
    display: none;
  }

  .white-logo {
    display: inline;
  }

  &.isWhite, &.isTop {
    .NavItem {
      color: var(--black);

      a {
        color: var(--black);
      }
    }

    background-color: #fff;
    z-index: 5000;
    .white-logo {
      display: none;
    }
    .black-logo {
      display: inline;  
    }
  }

  .menu-apply-button {
    margin-top: -20px;
    margin-right: 20px;
    font-size: 14px;
    box-shadow: #000 -5px 5px 0px;
    padding: 10px 15px;
  }

  @media screen and (max-width: 767px) {
    .white-logo {
      display: none;
    }
    .black-logo {
      display: inline;  
    }
  } 
`;

export default function PSNavBar({location, cohort}) {
  const whiteLocations = ['/course/', '/course', '/deposit/', '/deposit', '/prep', '/prep', '/apply', '/apply', '/faq', '/faq/'];
  const isWhite = whiteLocations.includes(location.pathname);

  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const fromTop = window.scrollY;

      if (fromTop < 100) {
        setIsTop(true);
       
      } else {
        setIsTop(false);
      }
    });
  })

  return (
    <StyledNavbar expand="lg" className={(isWhite ? 'isWhite' : null) + ' ' + (!isTop ? 'isTop' : null)}>
      <Link to='/'>
        <StyledNavbar.Brand className="logo">
          <img className='white-logo' src={logoWhite} alt=''></img>
          <img className='black-logo' src={logoBlack} alt=''></img>
        </StyledNavbar.Brand>
      </Link>
      <StyledNavbar.Toggle aria-controls="basic-navbar-nav" />
      <StyledNavbar.Collapse id="basic-navbar-nav">
        <Nav className='ml-auto'>
          <>
            <div className='NavItem'>
              <AnchorLink to={`/students/cohort-${cohort}#students`} title="Our team">
                <span>Students</span>
              </AnchorLink>
            </div>

            <div className='NavItem'>
              <AnchorLink to={`/students/cohort-${cohort}#forms`} title="Our team">
                <span>Forms & Links</span>
              </AnchorLink>
            </div>
          </>
        </Nav>
      </StyledNavbar.Collapse>
    </StyledNavbar>
  )
}